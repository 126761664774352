import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import Send from '../components/html/Send';
import GetName from '../components/html/GetName';
import InputHtml from '../components/html/InputHtml';
import CurrencyFormat from 'react-currency-format';

import  * as Util from './../data/General';
import Alert from '../components/html/Alert';
import SelectHtml from '../components/html/SelectHtml';
import RateSelector from '../components/widget/RateSelector';
import PackageDimensions from '../components/widget/PackageDimensions';
import moment from "moment";
import 'react-dates/initialize';
import 'moment/locale/es';
import CheckboxUnit from '../components/html/CheckboxUnit';
import CheckboxView from '../components/html/CheckboxView';
import ShippingOrder from '../components/html/ShippingOrder';

class Order extends Component {

    state = {
        isSearch: false,
        quotations:null, 
        checked: 0,
        checkedName: "",
        carrierId: 0,
        errorText:"",
        alertText: "",
        successText: "",
        shippingAddress: {},
        shippingOrign: null,
        orderEdit: false,
        textSearch: "Buscando datos de la orden",
        urlPDF: "",
        returnOrder: false,
        packageList:null,
        type: "order",
        insuredAmount: 0,
        pickupOption: "true",
        personalizad: {
            id: 0,  
            contentValue: 120.50,
            weight: 1,
            length: 1,
            height: 1,
            width: 1,
            measureUnit: "CM",
            weightUnit: "KG",
            name: "Personalizado"
        },
        pickup: null,
        newShipping: false,
        shippingTG: false,
        weight: 0,
        totalAmount: 0,
        step: 0,
        idShipping: 0,
        shopAddresses: -1,
        minDate: null,
        shop: null,
        carriers: Util.carriersDefault,
        partial: false,
        partialList: [],
        partialListShipped: [],
        transporter: null,
        isPrepago: false
    };

    componentDidMount() {
        //https://triciclogo-dev-guias.s3.amazonaws.com/dhl/0026285066.pdf
        const { orden, shipping, type, pickup, shopState, config, tomorrow, today, user } = this.props;
        Constants.scrollToWindow();
        var idOrder = window.location.pathname;

        if (type ==="order-shopify"){
            let param = new URLSearchParams(this.props.location.search);
            idOrder = param.get("id");
        }
        if(idOrder === "/new-shipping"){
            
            this.setState({ 
                newShipping: true,
                orderEdit: true,
                step: 0,
                shippingAddress:{
                    address1: "",
                    address2: "",
                    city: shopState[0].city,
                    zip: shopState[0].zip,
                    province: shopState[0].state,
                    country: shopState[0].country,
                    provinceCode: shopState[0].state,
                    countryCode: shopState[0].countryCode,
                    lastName: "",
                    firstName: "",
                    email: "",
                },
                shopAddresses: config.addressesDefault
            });
            this.props.dispatch({
                type: Constants.types.CLICK_ORDEN,
                orden: {}
            });
        }else if(idOrder === "/shipping-tg"){
            const { shipping } = this.props;
            let url = "";
            if (shipping.carrierId !== "LALAMOVE"){
                url = shipping.guideUrl;
            }else{
                url = shipping.trackerUrl;
            }

            if(shipping.destination){

                let zipCode = shipping.destination.zip;

                if (!zipCode){
                    zipCode = shipping.destination.zipCode;
                }
                this.setState({ 
                    urlPDF: url, 
                    step: 3,
                    shippingTG: true, 
                    newShipping: true,
                    shippingAddress: {
                        address1: shipping.destination.street1,
                        address2: shipping.destination.street2,
                        city: shipping.destination.city,
                        zip: zipCode,
                        province: shipping.destination.state,
                        country: shipping.destination.country,
                        provinceCode: shipping.destination.state,
                        countryCode: shipping.destination.countryCode,
                        lastName: shipping.destination.lastName,
                        firstName: shipping.destination.firstName,
                        email: shipping.destination.email,
                    },
                    shopAddresses: config.addressesDefault
                });
            }
        }else{
            let idShipping=0;
            let step = 0
            if(type === "pickup" || type === "pickup-local"){
                if(type === "pickup"){
                    let str = idOrder.replace("/pickup/", "");
                    let arr = str.split("/");
                    idOrder = arr[0];
                    idShipping = arr[1];
                }else{
                    idShipping =  idOrder.replace("/pickup-local/", "");
                    idOrder = "";
                    const { shipping } = this.props;
                    this.setState({ shippingAddress: shipping.destination});
                }    
                let now = moment();
                let minDate = ((now.hour()) >= 13 ? tomorrow : today);
                pickup["date"] = minDate;
                this.setState({ minDate: minDate, shippingOrign: shipping.origin});
                
            }
            
            if(type === "shipping"){
                step = 3;
                idOrder = idOrder.replace("/order-shipping/", "");
            }else{
                idOrder = idOrder.replace("/order/", "");
            }

            this.setUrlPDF(idOrder);
            if (orden && idOrder === orden.id){
                if(orden && orden.shippingAddress){
                    let shippingAddress = orden.shippingAddress;
                    this.setState({ shippingAddress: shippingAddress, type: type, pickup: pickup, step: step});
                }
                if (orden.fulfillmentStatus && orden.shippingLines && orden.shippingLines.length > 0){
                    if (type === "shipping"){
                        this.loadDataShipping(orden.shippingLines[orden.shippingLines.length - 1].id, type);
                    }else{
                        this.loadDataShipping(idOrder, type);
                    }
                }
                this.calculateWeight();
            }else if (type !== "pickup-local"){
                this.handleClickOrden(idOrder);
            }
           
            this.setState({ 
                type: type, 
                pickup: pickup, 
                idShipping: idShipping, 
                step: step,
                shopAddresses: config.addressesDefault
            });
            

            setTimeout(function(){
                Util.loadDataUsers(this.props.dispatch, false);
                if (!orden.fulfillmentStatus){
                    this.validateText();
                }
            }.bind(this), 500);
        }   
        
        let shop = shopState[0];
        if (orden && orden.customerId !== 0 && type !== "pickup-local"){
            shop = Util.getShop(shopState, orden.customerId);
            if(shop === null){
                shop = shopState[0]; 
            }
        }
        
        setTimeout(function(){
            this.loadDataCarriers(user.data.customerId[0]);
        }.bind(this), 500);


        let transporter = localStorage.getItem("transporter");
        if (transporter){
            transporter = JSON.parse(transporter);
            this.setState({transporter});
        }

        const { packageListProps } = this.props;
        //let packageListPropsCopy = Object.assign({}, packageListProps);
        //packageListPropsCopy.list.push(packageListProps.personalized);
        if (packageListProps.list.length > 1){
            if (packageListProps.list[packageListProps.list.length -1].name !== "Personalizado"){
                packageListProps.list.push(packageListProps.personalized);
            }
        }else{
            packageListProps.list.push(packageListProps.personalized);
        }

        let now = moment();
        let minDate = ((now.hour()) >= 13 ? tomorrow : today);
        pickup["date"] = minDate;


        this.setState({packageList: packageListProps, shop: shop, pickup: pickup, minDate: minDate});
    }

    loadPLS = (partialListShipped, shipping) => {
        if (shipping.items){
            Object.keys(shipping.items).map((key)=>{
                if (!partialListShipped){
                    partialListShipped = [];
                }
                let value = (shipping.items[key]);
                partialListShipped[value.id] = value;
                partialListShipped[value.id]["tracker"] = shipping.tracker;
                partialListShipped[value.id]["trackerUrl"] = shipping.trackerUrl;
                partialListShipped[value.id]["createDate"] = shipping.createDate;
                partialListShipped[value.id]["guideUrl"] = shipping.guideUrl;
                partialListShipped[value.id]["status"] = shipping.status;
            });
        }
        return partialListShipped;
    }

    loadPartialListShipped = (shipping) => {
        let partialListShipped = null;
        if (shipping && shipping.items){   
            partialListShipped = this.loadPLS(partialListShipped, shipping);
        }else{
            //alert("entro3");
            Object.keys(shipping).map((key)=>{
                let ship = (shipping[key]);
                partialListShipped = this.loadPLS(partialListShipped, ship);
            });
        }
        if (partialListShipped){
            this.setState({partialListShipped : partialListShipped, partial: true});
        }

    }

    loadDataCarriers = (idShop) => {
        const url = Constants.URL_API+"shippings/carriers/carrier-customer/?customerId="+idShop;
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            const { carriers } = this.state;
            if (data.length > 0){
                let isPrepago = false;
                for (var i=0; i < data.length; i++) {
                    var value= data[i];
                    carriers[value.carrierId] = value;
                    if (value.carrierId === "ESTAFETA"){
                        let carrieData = value.carrierData;
                        if (carrieData.prepago && carrieData.prepago !== ""){
                            isPrepago = true;
                        }
                    }
                }
                this.setState({carriers: carriers, isPrepago: isPrepago});
            }
        }); 
    }

    calculateWeight = () =>{
        const { orden, config } = this.props;
        let weight = 0;
        let amount=0;
        if(orden && orden.lineItems){
            Object.entries(orden.lineItems).map(([key,value])=>{
                weight = weight + value.grams;
                amount = amount + value.price; 
            });
            let insuredAmount = 0;
            if (config.insuredCargo === "true"){
                insuredAmount = amount;
            } 
            this.setState({
                weight: weight,
                totalAmount: amount,
                insuredAmount: insuredAmount
            });
        }
    }

    loadDataShipping = (idOrder, type) => {
        this.setState({ isSearch: true, step: -1});
        let url = Constants.URL_API+"shippings/?orderId="+idOrder;
        if (type === "shipping"){
            url = Constants.URL_API+"shippings/"+idOrder;
        }

        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            if (data.length > 0){
                let shipping = data;
                this.props.dispatch({
                    type: Constants.types.CHANGE_SHIPPING,
                    shipping: shipping
                });
                this.loadPartialListShipped(shipping);
                const { orden } = this.props;
                if (orden.fulfillmentStatus !== "partial"){
                    let shippingNew = shipping;
                    if (shipping.length > 0){
                        //alert("Ingresa2");
                        shippingNew=shipping[shipping.length-1];
                    }
                    let url = "";
                    if (shippingNew.carrierId !== "LALAMOVE"){
                        
                        if (shippingNew.carrierId !== "PAQUETEXPRESS"){
                            url = shippingNew.guideUrl;
                        }else{
                            url = "https://www.autososcar.com.ar/paquetexpress/?tracking="+shippingNew.tracker;
                        }  
                    }else{
                        url = shippingNew.trackerUrl;
                    }
                    if (url !== ""){
                        let step = 3;
                        const { type } = this.props;
                        if(type === "pickup"){
                            step = 0;
                        }
                        this.setState({ urlPDF: url,  step: step, isSearch: false});
                    }else{
                        this.setState({ isSearch: false});
                    }
                }else{
                    this.setState({ isSearch: false});
                }
            }else{
                this.setState({ isSearch: false});
            }
        }); 
    }

    setUrlPDF = (idOrder) => {
        const { shipping } = this.props;
        let shippingNew = shipping;
        if (shipping.length > 1){
            shippingNew=shipping[shipping.length-1];
        }
        if (shippingNew && shippingNew.orderId === idOrder){
            let url = "";
            if (shippingNew.carrierId !== "LALAMOVE"){
                if (shippingNew.carrierId !== "PAQUETEXPRESS"){
                    url = shippingNew.guideUrl;
                }else{
                    url = "https://www.autososcar.com.ar/paquetexpress/?tracking="+shippingNew.tracker;
                }    
            }else{
                url = shippingNew.trackerUrl;
            }
            
            this.setState({ urlPDF: url});
        }
    }

    handleClickOrden = (idOrden) => {
        this.setState({ isSearch: true, step: -1});
        const url = Constants.URL_API+"orders/"+idOrden;
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            this.props.dispatch({
                type: Constants.types.CLICK_ORDEN,
                orden: data
            });
            const { shopState } = this.props;
            let shop = Util.getShop(shopState, data.customerId);
            if(data && data.shippingAddress){
                this.setState({ shippingAddress: data.shippingAddress, isSearch: false, shop: shop});
            }else{
                this.setState({ isSearch: false, shop: shop});
            }
            this.calculateWeight();
        }); 
        return false;
    }


    handleClickPackage = (pack) => {
        const { packageList, personalizad } = this.state;
        let selected_package = null;
        if (pack === "0") {
            selected_package = Util.getPersonalizad(personalizad);
        }else{
            for (var i in packageList.list) {
                let element = packageList.list[i];
                if(element.id === pack){
                    selected_package = element;
                }
            }
        }
        packageList.selected = selected_package;
        this.setState({packageList: packageList});
        this.props.dispatch({
            type: Constants.types.CLICK_PACKAGE,
            selected: selected_package
        });
        return false;  
    }

    validateText = () => {
        const { shippingAddress } = this.state;
        const { orden } = this.props;
        //let isMax = Util.validateMax(shippingAddress, 35);
        let alertText = "";
        
        /*if (isMax){
            alertText = "Hay campos de la dirección que son muy largos, por favor tenga en cuenta que si continúa sin editar estos campos serán cortados. ";
        }*/
        if(shippingAddress && orden && orden.customer){
            /*if (shippingAddress.email === null ||  shippingAddress.email === "" || orden.customer.email === null || orden.customer.email === "" || orden.email === null || orden.email === ""){
                //alertText+="El correo del cliente esta vacio. "
            }*/
            if (shippingAddress.phone === null || shippingAddress.phone === ""){
                alertText+="El telefono del cliente esta vacio. "
            }
        }
            
        this.setState({ errorText: alertText}); 

    }

    getQuotion = (key, carrierId, packSelect, deliveryType, quotationsPartial) => {
        const { shippingAddress, returnOrder, insuredAmount, shopAddresses, shop, partial } = this.state;
        const {shopState, orden } = this.props;
        let quotationsPost={};
        let origin = {
            zipCode: Util.getDataShop(shop, shop.addresses, shopAddresses, "zip"),
            city: Util.getDataShop(shop, shop.addresses, shopAddresses, "city"),
        };
        let destination= {
            zipCode: shippingAddress.zip,
            city: shippingAddress.city,
        };
        if (returnOrder){
            //Devolución
            destination = {
                zipCode: shop.zip,
                city: shop.city,
            };
            origin= {
                zipCode: shippingAddress.zip,
                city: shippingAddress.city,
            };
        }
        //Eliminar cuando el api soporte decimales
        packSelect.weight = Math.round(packSelect.weight);
        packSelect.height = Math.round(packSelect.height);
        packSelect["length"] = Math.round(packSelect["length"]);
        packSelect.width = Math.round(packSelect.width);
        packSelect.volume = Math.round(packSelect.volume);
        packSelect.description = shop.titleGuide;

        quotationsPost = {
            package: packSelect,
            insuredAmount: insuredAmount,
            origin: origin,
            destination: destination,
            customerId: orden.customerId,
            orderId: orden.id,
        }

        if (partial){
            quotationsPost.items = quotationsPartial;
        }
        const url = Constants.URL_API+"shippings/quotations/?carrierId="+ carrierId+"&deliveryType="+deliveryType;
        axios.post(url, quotationsPost, Constants.getHeaders()).then(response => response.data, error => {
            //Constants.setErrors(error);
            const { quotations } = this.state;
            let errorRetrun = [];
            errorRetrun.push({
                code: error.response.status,
                message: Constants.getMessage(error.response.data.message),
                isError: true,
            });
            quotations[key] = errorRetrun;
            this.setState({ quotations: quotations });
            return error;
        }).then((data) => {
            if (!data.message) {
                let checked = 0;
                let carrierIdSelect = 0;
                const { quotations, transporter } = this.state;
                quotations[key] = data;
                /*console.log(quotations);
                let quotationsCopy = {};
                try{
                    let quotArray = Object.values(quotations);
                    console.log(quotArray);
                    quotArray.sort( this.compare );
                    console.log(quotArray);

                    for (let i =0; i<quotArray.length; i++){
                        var element = quotArray[i];
                        if (element["deliveryType"]){
                            quotationsCopy[element["deliveryType"]]= element;
                        }
                    }
                    console.log(quotationsCopy);
                }catch (e){
                    quotationsCopy =quotations;
                    console.log(e);
                }*/

                let checkBalance = true;
                if(data.length > 0){
                    checkBalance = Util.isCheckBalanceTransporter(data[0].carrierId, transporter);
                }
                if((data.length > 0 && (!checkBalance || data[0].total < shopState[0].quota))){//&& !this.isNotRFC(data[0].carrierId)
                    checked = data[0].rateId; 
                    carrierIdSelect = data[0].carrierId;
                    this.setState({ quotations: quotations, checked: checked, checkedName:data[0].carrier, carrierId: carrierIdSelect});
                }else{
                    this.setState({ quotations: quotations });
                }
            } 
        });
    }

    compare = ( a, b ) => {
        if ( (a.rateId && b.isError) || (a.rateId && a.total < b.total) ){
          return -1;
        }
        if ( (b.rateId && a.isError) || (b.rateId && b.total < a.total)){
          return 1;
        }
        return 0;
      }

    isNotRFC = (carrier) => {
        const { shop } = this.state;
        if(carrier ==="FEDEX" || carrier ==="ESTAFETA"){
            if (!shop.rfc || shop.rfc === ""){
                return true;
            }
            if (!shop.cartaPorteProductKey || shop.cartaPorteProductKey === ""){
                return true;
            }
        }
        return false;

    }

    quotations = () => {

        const { carriers, partial, partialList, transporter, isPrepago, packageList, personalizad } = this.state;

        let quotations = {};

        let quotationsPartial = [];
        if (partial){
            Object.keys(partialList).map((key)=>{
                let part = partialList[key];
                if (part && part.id){
                    quotationsPartial.push(part);
                } 
            });
        }

        let packSelect = 0;
        if (packageList.selected.id === 0) {
            packSelect = Util.getPersonalizad(personalizad);
        }else{
            packSelect = packageList.selected;
        } 
        //const { shopState } = this.props;
        Object.keys(carriers).map((key)=>{
            var value= carriers[key];
            if (value.enabled){
                //quotations.push(Util.CARRIERS_INFO[key].list);
                
                if (isPrepago && Util.CARRIERS_INFO[key].name === "Estafeta"){
                    Object.assign(quotations, {
                        "TERRESTRE_PREPAGO": [{}],
                        "DIA_SIG_PREPAGO": [{}],
                        
                      });
                }else{
                    Object.assign(quotations, Util.CARRIERS_INFO[key].list);
                    if (Util.CARRIERS_INFO[key].name === "Estafeta" && !Util.isCheckBalanceTransporter("ESTAFETA", transporter)){
                        Object.assign(quotations, {
                            "DOS_DIAS": [{}],
                          });
                    }
                }
                /*if (Util.CARRIERS_INFO[key].name === "Fedex" && (shopState && "26847740095" === shopState[0].id)){
                    Object.assign(quotations, {
                        "FEDEX_SAVER": [{}],
                        "FEDEX_STANDARD": [{}],
                      });
                }*/

                /*if (Util.CARRIERS_INFO[key].name === "RedPack" && packSelect.weight <= 1){
                    Object.assign(quotations, {
                        "REDPACK_EXPRESS": [{}],
                      });
                }*/

                if (Util.CARRIERS_INFO[key].name === "SHIPANDGO" && packSelect.weight > 1){
                    Object.assign(quotations, {
                        "G": [{}],
                      });
                }
                
            }
        });
        this.setState({ 
            step: 1, 
            quotations: quotations
        });
        

        if (packSelect.weight > 0){
            Object.entries(Util.CARRIERS_RATE).map(([key,value])=>{
                if (quotations.hasOwnProperty(key)){
                    this.getQuotion(key, value.carrierId, packSelect, value.deliveryType, quotationsPartial);
                }
            });
        }else{
            this.setState({ isSearch: false, alertText: "El peso debe ser mayor a 0"});
        }
    }

    pickups = () =>{
        this.setState({ quotations: {}, isSearch: true, step: -1, errorText: "",  textSearch: "Pautando recolección, por favor espere"});
        const { pickup, packageList, personalizad, idShipping, shippingOrign, addressSelected, shop } = this.state;
        const { user } = this.props;
        if(idShipping !== 0){
            let packSelect = 0;
            if (packageList.selected.id === 0) {
                packSelect = Util.getPersonalizad(personalizad);
            }else{
                packSelect = packageList.selected;
            } 
            //Eliminar cuando el api soporte decimales
             packSelect.weight = Math.round(packSelect.weight);
            packSelect.height = Math.round(packSelect.height);
            packSelect["length"] = Math.round(packSelect["length"]);
            packSelect.width = Math.round(packSelect.width);
            packSelect.volume = Math.round(packSelect.volume);

            let pickupSend = {
                shipmentId: idShipping,
                date: pickup.date,
                readyByTime: pickup.readyByTime,
                closeTime: pickup.closeTime,
                package: packSelect,
                origin: {
                    companyName: Util.textSubString(shop.contactName, 0, 34),
                    firstName: shop.titleGuide,
                    lastName: shop.contactName,
                    email: user.data.email,
                    phone: shippingOrign.phone,
                    street1: shippingOrign.street1,
                    street2: shippingOrign.street2,
                    city: shippingOrign.city,
                    state: shippingOrign.state,
                    country: shippingOrign.country,
                    countryCode: shippingOrign.countryCode,
                    reference: shippingOrign.reference,
                    zipCode: shippingOrign.zipCode,
                }
            }

            const url = Constants.URL_API+"shippings/pickups";
            axios.post(url, pickupSend, Constants.getHeaders()).then(response => response.data,error => {
                Constants.setErrors(error);
                if (error.response.status === 400) {
                    this.setState({ errorText: Constants.getMessage(error.response.data.message),isSearch: false, step: 0});
                }
                return error;
            }).then((data) => {
                Constants.scrollToWindow();
                if (data.id){
                    this.setState({ isSearch: false, textSearch: data, step: 0, successText: "Recolección pautada con éxito!"});
                }
            }); 
        }else{
            this.setState({ errorText: "Error al cargar los datos, por favor vuelva a intentar en un momento"});
        }
    }

    shippings = () => {
        this.setState({ quotations: {}, isSearch: true, step: -1, errorText: "",  textSearch: "Generando la guía, por favor espere"});
        const { orden, user } = this.props;
        const { checked, checkedName, partial, partialList, shippingAddress, packageList, returnOrder, personalizad, newShipping, insuredAmount, shopAddresses, shop, type } = this.state;

        let packSelect = 0;
        if (packageList.selected.id === 0) {
            packSelect = Util.getPersonalizad(personalizad);
        }else{
            packSelect = packageList.selected;
        } 

        //Eliminar cuando el api soporte decimales
        packSelect.weight = Math.round(packSelect.weight);
        packSelect.height = Math.round(packSelect.height);
        packSelect["length"] = Math.round(packSelect["length"]);
        packSelect.width = Math.round(packSelect.width);
        packSelect.volume = Math.round(packSelect.volume);


        let description = "";   

        if(orden && orden.lineItems){
            Object.entries(orden.lineItems).map(([key,value])=>{

                let textAdd = "pz de ";

                if("PAQUETEXPRESS" === checkedName){
                    textAdd = "PZ "+value.sku+" ";
                }
                if (!partial){
                    if (description !== ""){
                        description = description+ " - ";
                    }
                    description = description + value.quantity + textAdd + value.title;
                }else if (partial && partialList[value.id]){
                    if (description !== ""){
                        description = description+ " - ";
                    }
                    description = description + value.quantity + textAdd + value.title; 
                }

                
            });
        }
        packSelect.description  = description;
        
        let orderId = "";
        let orderName = "";
        let email = "";
        let phone = "";
        if (newShipping){
            orderId = "";
            orderName= "";
            phone = shippingAddress.phone;
            email = shippingAddress.email;
        }else{
            orderId = orden.id;
            orderName = orden.name;
            email = orden.email;
            phone = shippingAddress.phone;
        }
        if(type === "new"){
            orden.customerId= shop.id;
        }

        let shippingsPartial = [];
        if (partial){
            Object.keys(partialList).map((key)=>{
            //for (let part of partialList) {
                let part = partialList[key];
                if (part && part.id){
                    shippingsPartial.push(part);
                } 
            });
        }

        Util.shippings(orden, shop, shopAddresses, user, shippingAddress, packSelect, orderId, orderName, email, phone, insuredAmount, returnOrder, checked, this.handleOk, this.handleError, partial, shippingsPartial, checkedName);
    }

    handleError = (error, orderId) => {
        this.setState({ errorText: error, isSearch: false, step: 0, alertText: ""});
    }
    handleOk = (data) => {
        this.props.dispatch({
            type: 'CHANGE_SHIPPING',
            shipping: data
        });
        this.setState({ isSearch: false, step: 3, urlPDF: data.guideUrl, alertText: ""});


        //llamamos a recoleccion
        const { pickupOption } = this.state;

        if( pickupOption === "true"){
            setTimeout(function(){
                this.pickupsNewOrder();
              }.bind(this), 200);
        }


    }

    pickupsNewOrder = () =>{
        this.setState({ isSearch: true, errorText: "",  textSearch: "Pautando recolección, por favor espere"});
        const { pickup, packageList, shopAddresses, personalizad } = this.state;
        const { user, shopState, shipping } = this.props;

        let orderDate = shipping;

        let phoneShop = Util.getDataShop(shopState, shopState.addresses ,shopAddresses, "phone");
        if (phoneShop == null || phoneShop === ""){
            phoneShop = "9999 99999";
        }
        
        let shop = shopState[0];
        
        //Eliminar cuando el api soporte decimales
        let packSelect = 0;
        if (packageList.selected.id === 0) {
            packSelect = personalizad;
        }else{
            packSelect = packageList.selected;
        } 
        //Eliminar cuando el api soporte decimales
        packSelect.weight = Math.round(packSelect.weight);
        packSelect.height = Math.round(packSelect.height);
        packSelect["length"] = Math.round(packSelect["length"]);
        packSelect.width = Math.round(packSelect.width);
        packSelect.volume = Math.round(packSelect.volume);
        packSelect["packagesCount"] = 1;

        let pickupSend = {
            shipmentId: orderDate.id,
            date: pickup.date,
            readyByTime: pickup.readyByTime,
            closeTime: pickup.closeTime,
            package: packSelect,
            origin: {
                companyName: Util.textSubString(shop.contactName, 0, 34),
                firstName: shop.titleGuide,
                lastName: shop.contactName,
                email: user.data.email,
                phone: phoneShop,
                street1: Util.getDataShop(shop, shop.addresses ,shopAddresses, "street1"),
                street2: Util.getDataShop(shop, shop.addresses ,shopAddresses, "street2"),
                "number": "900",
                city: Util.getDataShop(shop, shop.addresses ,shopAddresses, "city"),
                state: Util.getDataShop(shop, shop.addresses ,shopAddresses, "state"),
                country: Util.getDataShop(shop, shop.addresses ,shopAddresses, "country"),
                countryCode: Util.getDataShop(shop, shop.addresses ,shopAddresses, "countryCode"),
                reference: Util.getDataShop(shop, shop.addresses ,shopAddresses, "reference"),
                zipCode: Util.getDataShop(shop, shop.addresses ,shopAddresses, "zip")
            }
        }

       
        const url = Constants.URL_API+"shippings/pickups";
        axios.post(url, pickupSend, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            if (error.response.status === 400) {
                this.setState({ errorText: "ETIQUETA CREADA CORRECTAMENTE, fallo al querer pautar la recolección ("+error.response.data.message+")",isSearch: false, step: 0});
            }
            return error;
        }).then((data) => {
            if (data.id){
                this.setState({ isSearch: false, textSearch: data, successText: "Recolección pautada con éxito!"});
            }
        }); 
       
    }

    handleClick = (id, name) => {
        this.setState({ checked: id, checkedName: name, orderEdit: false});
    }
    
    handleChange = (value, name) =>{
        const { shippingAddress } = this.state;
        let shippingAddressCopy = JSON.parse(JSON.stringify(shippingAddress));
        shippingAddressCopy[name] = value;
        if("countryCode" === name){
            shippingAddressCopy["country"] = Util.COUNTRY[value];
        }

        if("province" === name){
            shippingAddressCopy["provinceCode"] = value;
        }
        this.setState({
            shippingAddress: shippingAddressCopy
        });
    }
    handleChangeIA  = (value, name) =>{
        if (value === ""){ 
            value = 0;
        }
        this.setState({
            insuredAmount: value
        });
    }

    handleChangeAddress  = (value, name) =>{
        
        this.setState({
            shopAddresses: value
        });
    }

    handlePersonalizad = (value, name) =>{
        const { personalizad } = this.state;
        personalizad[name] = value;
        this.setState({
            personalizad: personalizad
        });
    }

    handlePickup = (value, name) =>{
        const { pickup } = this.state;
        pickup[name] = value;
        this.setState({
            pickup: pickup
        });
    }
    updateInsuredAmount = (value) => {
        const { totalAmount } = this.state;
        if (value === "true"){
            this.setState({
                insuredAmount: totalAmount
            });
        }else{
            this.setState({
                insuredAmount: 0
            });
        }
    }

    updatePickupOption = (value) => {
        this.setState({
            pickupOption: value
        });
    }

    handleCancel = () =>{
        const { newShipping } = this.state;
        if (!newShipping){
            const { orden } = this.props;
            this.setState({
                shippingAddress: orden.shippingAddress,
                orderEdit: false
            });
        }else{
            this.setState({ 
                shippingAddress:{
                    address1: "",
                    address2: "",
                    city: "",
                    zip: "",
                    province: "",
                    country: "Mexico"
                },
                orderEdit: false
            });
        }
    }

    handleAcept = () => {
        this.setState({
            orderEdit: false
        });  

        const { step } = this.state;

        if (step === 1){
            this.quotations();
        }
    }

    step = (newstep) => {
        if (newstep === 85){
            this.setState({
                step: 1,
                orderEdit: true
            });
        } else{
            this.setState({
                step: newstep
            });
        }
    }

    printAccuse = (value) => {
        window.location.href = "/print/local-accuse?id=" + value;
    }

    changeCheckPartial = () =>{
        const { partial } = this.state;

        this.setState({partial: !partial});
    }

    selectedPartial = (selected, id, extra) => {
        const { partialList } = this.state;

        if (selected){
            partialList[id] = {
                "id": id,
                "quantity": extra,
            };
        }else{
            partialList[id] = null;
        }
        this.setState({partialList: partialList});
    }
  
    render() {
        const { orden, shipping, packageListProps, config, shopState } = this.props;
        const { isSearch, partial, partialListShipped, partialList, transporter, shippingOrign, shop, step, type, shippingTG, successText, totalAmount, insuredAmount, weight, pickup, minDate, alertText, quotations, packageList, checked, errorText, shippingAddress, orderEdit, textSearch, urlPDF, returnOrder, personalizad, newShipping, shopAddresses } = this.state;
        return (
            <div className="orden">
                {isSearch && ( 
                    <div className="center content-loading">
                        <div className="loading">
                            <div id="preloader">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div> 
                            <span>{textSearch}</span>
                        </div>    
                    </div>
                )}
                <div className=" content-flex space-between-start">
                    <div className="title">
                        {(!newShipping && !shippingTG) ? (
                            <React.Fragment>
                                <h1>
                                    {orden.name} 
                                </h1>
                                <div className="sub-title">
                                    <p>{orden.createdAt}</p>
                                </div>
                                <Send fulfillmentStatus={orden.fulfillmentStatus} />&nbsp;
                                {returnOrder && (<sapn className="send"><i className="icofont-map-pins"></i> Devolviendo un paquete!</sapn>)}
                            </React.Fragment>    
                        ) : (
                            <h1>
                                Nuevo envio
                            </h1>
                        )}
                    </div>
                    {shipping && shipping.tracker && shipping.carrierId === "LALAMOVE" && (
                        <button 
                            onClick={() => this.printAccuse(shipping.tracker)} 
                            className="btn triciclo top-middle right print last" 
                            disabled={!isSearch ? false : true}
                            >
                            <i className="icofont-printer"></i>&nbsp;
                            Imprimir acuse
                        </button>
                    )}
                </div>
                <div className="content-flex">
                    <div className="content-widget">
                        <Alert errorText={errorText} warningText={alertText} successText={successText} />
                        {step === 1 ? (
                            <RateSelector 
                                shop={shopState[0]} 
                                quotations={quotations} 
                                isSearch={isSearch} 
                                checked={checked} 
                                handleClick={this.handleClick} 
                                shippings={this.shippings}
                                step={this.step}
                                cp={shippingAddress.zip}
                                transporter={transporter}
                                shippingAddress={shippingAddress}
                                orderEdit={orderEdit}
                            />
                        ) : step === 0 ? (
                            <PackageDimensions 
                                type={type} 
                                pickup={pickup} 
                                isSearch={isSearch}
                                packageList={packageList} 
                                packageListProps={packageListProps} 
                                config={config} 
                                totalAmount={totalAmount} 
                                newShipping={newShipping} 
                                shippingTG={shippingTG} 
                                weight={weight} 
                                orderEdit={orderEdit} 
                                personalizad={personalizad}
                                insuredAmount={insuredAmount}
                                minDate={minDate}
                                handlePersonalizad={this.handlePersonalizad}
                                handlePickup={this.handlePickup}
                                handleClickPackage={this.handleClickPackage}
                                pickups={this.pickups}
                                quotations={this.quotations}
                                handleChangeIA={this.handleChangeIA}
                                updateInsuredAmount={this.updateInsuredAmount}
                                updatePickupOption={this.updatePickupOption}
                                step={this.step}
                                countryCode={shippingAddress.countryCode}
                            />     
                        ) : step === 3 ? (
                                <React.Fragment>
                                    <h4>
                                        Etiqueta 
                                        <button 
                                            onClick={() => this.setState({returnOrder:true, urlPDF : null, step: 0})} 
                                            className="btn triciclo right" disabled={isSearch ? true : false}
                                        >
                                            Generar guía de devolución
                                        </button>
                                        <button 
                                            onClick={() => this.setState({urlPDF : null, step: 0})} 
                                            className="btn secondary right" disabled={isSearch ? true : false}
                                        >
                                            Generar nueva guía
                                        </button>
                                    </h4>
                                    <hr></hr>
                                    {shipping.status !== "CANCELED"  && urlPDF &&  (
                                        <iframe src={`${urlPDF.replace("https://triciclogo-prod-guias.s3.amazonaws.com/", "https://shopify.triciclogo.com/getPDF/?pdf=")}#view=FitH`} style={{width:"100%", height: "70vh"}}/>  
                                    )}  
                                </React.Fragment>
                            ) : step === 4 ? (
                                <div className="no-data">
                                    <div className="content-flex flex-center">
                                        <i className="icofont-close-squared-alt"></i>
                                    </div>    
                                    <div className="content-flex flex-center">
                                        <h2>No tiene información del cliente</h2>
                                    </div>      
                                </div>
                            ) : (
                                <div className="center">
                                    <img className="center total" src="/img/load-order.gif" alt="Cargando" />
                                </div>
                            )}      
                        </div>
                        {((orden && orden.customer) || newShipping || shippingTG) ? (
                            <div className="content-widget client">
                                <h5>Dirección de origen</h5>
                                <hr></hr>
                                {shop && shippingOrign === null ? (
                                    <SelectHtml edit={orderEdit} options={shop.addresses} required={true} onChange={this.handleChangeAddress} name="shopAddresses" defaultText={`Principal: ${Util.getDataShop(shop, shop.addresses, shopAddresses, "street1")}, ${Util.getDataShop(shop, shop.addresses, shopAddresses, "city")}, ${Util.getDataShop(shop, shop.addresses, shopAddresses, "zip")}  `} defaultValue={-1} value={shopAddresses} keyValue={Constants.addressSelect}/> 
                                ) : shop && shippingOrign && (
                                    <label class="mode-edit">{shippingOrign.street1}, {shippingOrign.street2}, {shippingOrign.city}, {shippingOrign.zipCode} </label>
                                )}
                                <div className="address-order">
                                    <h5>Cliente</h5>
                                    <hr></hr>
                                    {(!newShipping && !shippingTG && type !== "pickup-local") && (
                                        <React.Fragment>
                                            <p><GetName customer={orden.customer} /></p>
                                            <p>{orden.customer.email}</p>
                                        </React.Fragment>    
                                    )}
                                    {(shippingTG || type === "pickup-local")  && (
                                        <React.Fragment>
                                            <p><GetName customer={shippingAddress} /></p>
                                            <p>{shippingAddress.email}</p>
                                        </React.Fragment>    
                                    )}
                                    {newShipping && (
                                        <React.Fragment>                                            
                                            <div className="label">{orderEdit && "Nombre:"} <InputHtml required={true} type="text" edit={orderEdit} onBlur={this.handleChange} name="firstName" value={shippingAddress.firstName}/></div>
                                            <div className="label">{orderEdit && "Apellido:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="lastName" value={shippingAddress.lastName}/></div>
                                            <div className="label">{orderEdit && "Email:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="email" value={shippingAddress.email}/></div>
                                        </React.Fragment>
                                    )}
                                </div>
                                {(!newShipping && !shippingTG) && shipping && shipping.orderId === orden.id ? (
                                    <div className="state-shipping">
                                        <hr></hr>
                                        <h5>Estado del envio</h5>
                                        <hr></hr>
                                        <ShippingOrder shipping={shipping} Constants={Constants} />
                                    </div>
                                ) : (shipping && shipping.length > 0 && orden.fulfillmentStatus) && (
                                    <div className="state-shipping">
                                        <hr></hr>
                                        <h5>Estado del envio</h5>
                                        <hr></hr>
                                        {Object.keys(shipping).map((key)=>(
                                            <React.Fragment key={key}>
                                                <ShippingOrder shipping={shipping[key]} Constants={Constants} />
                                                <hr></hr>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                )}
                                {shippingAddress && (
                                    <div>
                                        <hr></hr>
                                        <h5>Dirección de envio</h5>
                                        <hr></hr>
                                        {!newShipping && <p><GetName customer={shippingAddress} /></p>}
                                        <p>{orden.shippingLines && orden.shippingLines[0] && orden.shippingLines[0].title && (
                                            <span>{orden.shippingLines[0].title}</span>
                                            )}
                                        </p> 
                                        {((shippingAddress && shippingAddress.address1)  || orderEdit) && (
                                        <React.Fragment>
                                            <div className="label">{orderEdit && "Teléfono:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="phone" value={Util.getPhone(shippingAddress.phone)}/></div>
                                            <div className="label">{orderEdit && "Dirección:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="address1" value={shippingAddress.address1}/></div>
                                            <div className="label">{orderEdit && "Colonia:"} <InputHtml type="text" edit={orderEdit} onBlur={this.handleChange} name="address2" value={shippingAddress.address2}/></div>
                                            <div className="label">{orderEdit && "Compañía:"} <InputHtml type="text" edit={orderEdit} onBlur={this.handleChange} name="company" value={shippingAddress.company}/></div>
                                            <div className="label">{orderEdit && "Ciudad:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="city" value={shippingAddress.city}/></div>
                                            <div className="label">{orderEdit && "Código postal:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="zip" value={shippingAddress.zip}/></div>
                                            <div className="label">{orderEdit && "Estado:"} <InputHtml type="text" edit={orderEdit} required={true} onBlur={this.handleChange} name="province" value={shippingAddress.province}/></div>
                                            <div className="label">{orderEdit && "País:"} <SelectHtml edit={orderEdit} options={Util.COUNTRY} required={true} onChange={this.handleChange} name="countryCode" value={shippingAddress.countryCode}/></div>
                                        </React.Fragment>
                                        )}
                                        
                                        {!orderEdit && !shippingTG && step === 0 ? (
                                            <div className="content-flex flex-end">
                                                <button onClick={() => this.setState({orderEdit: true})} className="btn btn-secondary">Editar</button>
                                            </div>
                                        ) : ((!shippingTG && step === 0) || (step === 1 && orderEdit)) && (
                                            <React.Fragment>
                                                <div className="content-flex flex-end">
                                                    <button onClick={() => this.handleCancel()} className="btn btn-link">Cancelar</button>
                                                    <button onClick={() => this.handleAcept()} className="btn triciclo" disabled={(shippingAddress.address1 === "" || shippingAddress.city === "" || shippingAddress.email === "" || shippingAddress.phone === "" || shippingAddress.zip === "" || shippingAddress.province === "") ? true : false}>Guardar</button>
                                                </div>
                                            </React.Fragment>    
                                        )}   
                                    </div>
                                )}  
                            </div>
                        ) : orden && !orden.customer && (
                            <div className="content-widget client">
                                <h5>Cliente</h5>
                                <hr></hr>
                                <p>Ningún cliente</p>
                            </div>
                        )}
                        
                    </div>
                    {orden && orden.lineItems && type !== "pickup-local" && !newShipping && !shippingTG && (
                        <div className="content-flex">
                            <div className="content-widget not-margin-right">
                                <h5>Productos por un valor total de <CurrencyFormat value={totalAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <hr></hr> 
                                <div className="content-flex flex-center-left">
                                    <p className="no-margin">Surtido parcial&nbsp;&nbsp;</p>
                                    <CheckboxView 
                                        edit={step === 0}
                                        selected={partial}
                                        textActive="Activo"
                                        textDisabled="Desactivado"
                                        className="orderSwitchBtn"
                                        onChange={() => this.changeCheckPartial()}
                                    />
                                </div>
                                <table>
                                        <thead>
                                            <tr>
                                                {partial && (
                                                    <th>Se envia</th>    
                                                )}
                                                <th></th> 
                                                <th className="width-20">Nombre</th>
                                                <th className="center">Peso</th>
                                                <th>SKU</th>
                                                <th>Precio unitario</th>
                                                <th className="center">Cantidad</th>
                                                <th>Precio total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {orden && orden.lineItems && orden.lineItems.map((item, index) => (
                                            <tr key={index} className={(partialListShipped[item.id] && partialListShipped[item.id].id) ? "tr-shipping" : ((partialList[item.id] && partialList[item.id].id) ? "tr-active" : "")}>
                                                {partial && (
                                                    <td>
                                                        {(partialListShipped[item.id] && partialListShipped[item.id].id) ? (
                                                            <React.Fragment>
                                                                <CheckboxUnit 
                                                                    edit={step === 0} 
                                                                    id={item.id} 
                                                                    label={(step === 0) ? "" : "Enviada"}
                                                                    name="list" 
                                                                    selected={true}
                                                                    disabled={true}
                                                                />
                                                                <div><i className="icofont-calendar"></i> <span>{Constants.getDate(partialListShipped[item.id].createDate)}</span></div>
                                                                {Constants.status[partialListShipped[item.id].status] ? (
                                                                        <div><i className={Constants.status_icon[partialListShipped[item.id].status]}></i> <span>{Constants.status[partialListShipped[item.id].status]}</span></div>
                                                                    ) : <div><i className="icofont-clock-time"></i> <span>Sin Estado</span></div>
                                                                }
                                                                <div><a href={partialListShipped[item.id].trackerUrl} target="_blank">{partialListShipped[item.id].tracker}</a> - <a href={partialListShipped[item.id].guideUrl} target="_blank">Ver Guía</a></div>
                                                                
                                                            </React.Fragment>
                                                        ) : (
                                                                <CheckboxUnit 
                                                                edit={step === 0} 
                                                                id={item.id} 
                                                                label={(step === 0) ? "" : ((partialList[item.id] && partialList[item.id].id) ? "Si" : "No")}
                                                                name="list" 
                                                                extra={item.quantity}
                                                                selected={(partialList[item.id] && partialList[item.id].id) ? true : false} 
                                                                onBlurExtra={this.selectedPartial}
                                                                disabled={false}
                                                            /> 
                                                        )}
                                                    </td>
                                                )}
                                                <td>
                                                    {item.images && item.images.length > 0 && (
                                                        <img src={item.images[0].src} alt={item.title} className="img-prod"/>
                                                    )}
                                                </td>
                                                <td>
                                                    {item.title}
                                                </td>
                                                <td className="center">{item.grams}</td>
                                                <td>
                                                    {shop && (
                                                        <a href={`https://${shop.shopifyDomain}/search?q=${item.sku}`} target="_blank">
                                                            {item.sku}
                                                        </a>
                                                    )}
                                                </td>
                                                <td><CurrencyFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                <td className="center">{item.quantity}</td>
                                                <td><CurrencyFormat value={item.price * item.quantity} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                            </div>            
                        </div>
                    )}

                </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    orden: state.orden,
    packageListProps: state.packageList,
    shopState: state.shop,
    user: state.user,
    shipping: state.shipping,
    today: state.today,
    tomorrow: state.tomorrow,
    pickup: state.pickups,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Order);
